.two{
    position: relative;
    display: inline-flex;
    width: 100vw;
    
    height: max-content;
    padding: 15px;
    margin: 0 auto;
    text-align: justify;
    justify-content: center;
}

.two-inner{
    display: flex;
    position: relative;
    padding: 30px;
    /* letter-spacing: 10em; */
    /* width: 550px; */
}
h3{
    /* font-style: italic; */
    /* font-weight: light; */
}
.two-inner p{
    /* font-weight:bold; */
}

