footer{
    display: inline-block;
    position: relative;
    height: 80vh;
    width: 100vw;
    bottom: 0px;
    margin: 0;
    padding: 0;
    
    
}
#footer-container{
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;

}

#footer-text{
    margin: 0 auto;
    width: 500px;
    position: relative;
   top: 50%;
    transform: translateY(-50%);
    
}
#prj-container{
  position: relative;
  top: 30%;
  transform: translateY(-30%);
}