#block-container{
    position: relative;
    display: inline-flex;
    width: 100%;
    height: 100vh;
    background-color: #3782F3;
    font-family: Antic Didone,Arial, sans-serif;
    overflow: hidden;
    z-index: 0;
    /* margin: 0px 0px 50px 0px; */
    
}
#video-container{
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    /* padding: 40px; */
    max-width: 450px;
    /* border: 10px solid #3782F3; */
    /* overflow: hidden; */
    
}
#video-container video{
    border-radius: 40px;
    /* max-width: 450px; */
    width: 100%;
    /* height: auto; */
   
    
}
#edu-box h3{
    text-align: center;
}
#edu-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: translateX(-10%);
    margin: 0 auto; 
    color: white;
}
#edu-box a{
    color: white;
}
li{
    list-style: none;
}