@import url(https://fonts.googleapis.com/css2?family=Antic+Didone&family=Arapey&family=Satisfy&family=Scope+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Antic+Didone&family=Arapey&display=swap);
body {
  margin: 0;
  font-family: Scope One, serif;
  font-style: normal;
  font-weight: normal;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


html, .App{
  background-color: #EAD2C5;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  min-width: 850px;
  font-family: Antic Didone;
}
header{
  position: fixed;
  top: 10px;
  right: 50px;
  z-index: 1;
  
}
a{
  color: #000;
  -webkit-text-decoration:dotted;
          text-decoration:dotted;

}
header #contact-header{
  padding-right: 10px;
}



.three{
 position: relative;
 height: -webkit-max-content;
 height: -moz-max-content;
 height: max-content;
 margin: 0px;
 padding: 0px;
 width: 100vw;
}
.two{
    position: relative;
    display: inline-flex;
    width: 100vw;
    
    height: -webkit-max-content;
    
    height: -moz-max-content;
    
    height: max-content;
    padding: 15px;
    margin: 0 auto;
    text-align: justify;
    justify-content: center;
}

.two-inner{
    display: flex;
    position: relative;
    padding: 30px;
    /* letter-spacing: 10em; */
    /* width: 550px; */
}
h3{
    /* font-style: italic; */
    /* font-weight: light; */
}
.two-inner p{
    /* font-weight:bold; */
}


/* html{
  background-color: #EAD2C5;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
} */
#presentation-container{
  display: relative;
  width: 100vw;
  min-width: 860px;
  /* border: 3px solid #F33; */
  height: 602px;
  margin: auto 0;
}
p{
  padding: 0;
  margin: 0;
}
#inner{
  display: absolute;
  /* background-color: #41517C; */
  height: inherit;
  transform: translateY(5%);
}
h1{
  font-family: 'Antic Didone', serif;
  color: #41517C;
}
#canvas{
  position: relative;
  z-index: -1;
  overflow: hidden;
  /* height: 70%; */
}
#canvas-container{
  position: absolute;
  overflow: hidden;
  /* transform: translateX(100px); */
  width: 90vw;

}

#nameHeader{
  transform: translate(20px,450px)rotateZ(-90deg);
  transform-origin: top left;
  font-size: 44px;
  /* color: green; */
}

.arrow-down.icon {
  color: #000;
  position: absolute;
  right: 50px;
  top: 56px;
  margin-left: 10px;
  margin-top: 2px;
  width: 1px;
  height: 415px;
  background-color: currentColor;
}

.arrow-down.icon:before {
  content: '';
  position: absolute;
  left: -5px;
  top: 404px;
  width: 10px;
  height: 10px;
  border-top: solid 1px currentColor;
  border-right: solid 1px currentColor;
  transform: rotate(135deg);
}



#line-container{
  position: relative;
  bottom: -390px;  
  width: 100vw;
  padding: 0;
  
}
#line-container p{
    text-align: center;
    padding: 0;
    font-family: Antic Didone;
    /* font-size: small; */
}
#line{
  position: relative;
  margin: 0 auto;
  width: 80vw;
  border-top: 1px solid #41517C;
  padding: 0;
  
}




/* @import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap'); */
#skillList{
    width: 400px;
    position: relative;
    
    top: 0px;
    /* font-family: Antic Didone; */
    
    font-style: normal;
    font-weight: normal;
    /* font-size: 13px; */
    line-height: 10px;
    color: #000000;
    padding: 0;
    margin: 0;
}
#skillList li{
    list-style-type: none;
}
#skillList ul{
    margin: 0;
    padding: 5px;
}

.skillContainer{
    display: inline-flex;
    font-family: Scope One, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 0;
}
.skillContainer h3{
    width: 80px;
    font-style: normal;
    /* background-color: aquamarine; */
}
#skillBar{
    position: relative;
    /* font-family: Satisfy, cursive; */
    /* background-color: blanchedalmond; */
    height: 2px;
    width: 250px;
    bottom: -10px;
    -webkit-backface-visibility:hidden;
    backface-visibility: hidden;
    /* background-color: red; */
}
#skillBar #bar{
    transition: all 1.30s cubic-bezier(.42,-0.02,.18,1.01);
    position: absolute;
    height: 2px;
    width: 0;
    background-color: #000000;
    top: 6px;
    /* transform: translateY(6px); */
    /* border-bottom: 1px solid black; */
}

#exp-container{
    position: relative;
    width: 100vw; 
    /* font-family: Antic Didone; */
    font-family: Antic Didone;
    margin: 50px 0 0px 0;
    /* background-color: bisque; */
    height: 90vh;
}
#exp-container a{
    /* color: #41517C; */
    text-decoration: underline;
}
#exp-box{
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    margin: 0 auto; 
    border: 1px solid #000000;
    color: #000000;
    border-radius: 20px;
    width: 700px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
   
}
#exp-box h3{
    text-align-last: center;
    position: absolute;
    margin: 0 auto; 
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
    background-color:  #EAD2C5;
    width: 250px;

}


li{
    padding: 10px 30px 10px 10px;

}
#block-container{
    position: relative;
    display: inline-flex;
    width: 100%;
    height: 100vh;
    background-color: #3782F3;
    font-family: Antic Didone,Arial, sans-serif;
    overflow: hidden;
    z-index: 0;
    /* margin: 0px 0px 50px 0px; */
    
}
#video-container{
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    /* padding: 40px; */
    max-width: 450px;
    /* border: 10px solid #3782F3; */
    /* overflow: hidden; */
    
}
#video-container video{
    border-radius: 40px;
    /* max-width: 450px; */
    width: 100%;
    /* height: auto; */
   
    
}
#edu-box h3{
    text-align: center;
}
#edu-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: translateX(-10%);
    margin: 0 auto; 
    color: white;
}
#edu-box a{
    color: white;
}
li{
    list-style: none;
}
footer{
    display: inline-block;
    position: relative;
    height: 80vh;
    width: 100vw;
    bottom: 0px;
    margin: 0;
    padding: 0;
    
    
}
#footer-container{
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;

}

#footer-text{
    margin: 0 auto;
    width: 500px;
    position: relative;
   top: 50%;
    transform: translateY(-50%);
    
}
#prj-container{
  position: relative;
  top: 30%;
  transform: translateY(-30%);
}
#prj-container{

    width: 100%; 
    font-family: Antic Didone;
    /* margin: 150px 0px 70px 0px; */
    /* background-color: bisque; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}
#prj-container a{
    color: #000000;
    -webkit-text-decoration: dotted;
            text-decoration: dotted;
}
#prj-box{
    text-align: left;
   
    position: relative;
    margin: 0 auto; 
    border: 1px solid #000000;
    color: #000000;
    border-radius: 20px;
    width: 700px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
   
}
#prj-box h3{
    text-align-last: center;
    position: absolute;
    margin: 0 auto; 
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
    background-color:  #EAD2C5;
    width: 250px;

}

#prj-box ul{
    display: inline-flex;
}
li{
    padding: 10px 30px 10px 10px;

}
