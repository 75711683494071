#prj-container{

    width: 100%; 
    font-family: Antic Didone;
    /* margin: 150px 0px 70px 0px; */
    /* background-color: bisque; */
    height: max-content;
}
#prj-container a{
    color: #000000;
    text-decoration: dotted;
}
#prj-box{
    text-align: left;
   
    position: relative;
    margin: 0 auto; 
    border: 1px solid #000000;
    color: #000000;
    border-radius: 20px;
    width: 700px;
    height: max-content;
   
}
#prj-box h3{
    text-align-last: center;
    position: absolute;
    margin: 0 auto; 
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
    background-color:  #EAD2C5;
    width: 250px;

}

#prj-box ul{
    display: inline-flex;
}
li{
    padding: 10px 30px 10px 10px;

}